.image-wrapper {
	position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.image {
	background-size: cover !important;
	background-repeat: no-repeat;
	background-position: center center;
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image-info {
    display: flex;
    align-items: center;
    background-color: #fff;
    color:#000;
    padding: 1em;
}

.image-info img {
    border-radius: 50%;
}

.image-info h4 {
    display: inline-flex;
    margin-left: 1em;
    font-weight: 600;
}

.image-effect {
    display: block;
}

.image-effect:hover {
    box-shadow: 0 4px 8px rgba(255,255,255,1);
}